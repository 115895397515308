import React, { Component } from 'react';

export default class PageNotFound extends Component {
	render() {
		return (
			<>
				<div>Page Not Found</div>
			</>
		);
	}
}
